<template>
  <FilterOverview :isInitiallyLoading="false" :showFilterInitially="false" title="KPI Leading">
    <template #toolbar-left>
      <b-button-group size="sm" class="mr-4" style="width: 300px">
        <b-button
          v-for="kpiSite in kpiSites"
          :disabled="isBusy"
          :key="kpiSite"
          class="mr-1"
          :id="kpiSite"
          :variant="kpiSite === 'Leading' ? 'primary' : ''"
          style="width: 33%"
          :to="'/reisetermine/fvc/kpi-' + kpiSite.toLowerCase()"
        >
          {{ kpiSite }}
        </b-button>
      </b-button-group>
      <DateRangePicker
        :disabled="isBusy"
        style="width: 180px"
        isWeeklyDaterangePicker
        placeholder="Zeitraum"
        :value="zeitraumSelected"
        @input="setZeitraum"
      />
      <b-button-group size="sm" class="ml-4">
        <b-button
          v-for="regionsmanager in regionsmanagers"
          :key="regionsmanager"
          :id="regionsmanager"
          :disabled="isBusy"
          @click="onRegionsmanagerFilterClick"
          :variant="
            regionsmanagersSelected.includes(regionsmanager)
              ? 'primary'
              : regionsmanagersSelected.length === 0 && regionsmanager === 'Alle'
              ? 'primary'
              : ''
          "
          class="mr-1"
          style="width: 33%"
        >
          {{ regionsmanager }}
        </b-button>
      </b-button-group>
    </template>
    <template #table>
      <b-overlay :show="isBusy" opacity="1">
        <div class="card card-custom">
          <div
            class="kpi-grid"
            style="display: grid; gap: 1rem; padding: 1rem; grid-template-columns: repeat(2, 1fr)"
          >
            <DoughnutChart
              style="min-width: 500px; height: 400px"
              :data="isBusy ? [] : getLeadingKPIs?.gesamtverteilungStatusFrankfurtGesamt"
              :fillArray="fills"
              :title="'Status FRA: Gesamt'"
            />
            <DoughnutChart
              style="min-width: 500px; height: 400px"
              :data="isBusy ? [] : getLeadingKPIsStatusNachBearbeitung.statusNachBearbeitungFraGesamt"
              :fillArray="fillsStatusNachBearbeitung"
              :title="'Status nach Bearbeitung FRA: Gesamt'"
              :innerLabelSubtitle="'positiv'"
              :doNotShowTypes="['Aufpreis <200', 'Total']"
              :ausschlussTyp="ausschlussTypenStatusNachBearbeitung"
              :totalOverwrite="
                getTotalOverwrite(getLeadingKPIsStatusNachBearbeitung.statusNachBearbeitungFraGesamt)
              "
              :showPercentage="true"
            />
            <DoughnutChart
              style="min-width: 500px; height: 400px"
              :data="isBusy ? [] : getLeadingKPIs?.gesamtverteilungStatusFrankfurtFern"
              :fillArray="fills"
              :title="'Status FRA: Fern'"
            />
            <DoughnutChart
              style="min-width: 500px; height: 400px"
              :data="isBusy ? [] : getLeadingKPIsStatusNachBearbeitung.statusNachBearbeitungFraFernAD"
              :fillArray="fillsStatusNachBearbeitung"
              :doNotShowTypes="['Aufpreis <200', 'Total']"
              :title="'Status nach Bearbeitung FRA: Fern'"
              :innerLabelSubtitle="'positiv'"
              :totalOverwrite="
                getTotalOverwrite(getLeadingKPIsStatusNachBearbeitung.statusNachBearbeitungFraFernAD)
              "
              :ausschlussTyp="ausschlussTypenStatusNachBearbeitung"
              :showPercentage="true"
            />
            <DoughnutChart
              style="min-width: 500px; height: 400px"
              :data="isBusy ? [] : getLeadingKPIs?.gesamtverteilungStatusFrankfurtEu"
              :fillArray="fills"
              :title="'Status FRA: EU'"
            />
            <DoughnutChart
              style="min-width: 500px; height: 400px"
              :data="isBusy ? [] : getLeadingKPIsStatusNachBearbeitung.statusNachBearbeitungFraEuAD"
              :fillArray="fillsStatusNachBearbeitung"
              :doNotShowTypes="['Aufpreis <200', 'Total']"
              :title="'Status nach Bearbeitung FRA: EU'"
              :innerLabelSubtitle="'positiv'"
              :totalOverwrite="
                getTotalOverwrite(getLeadingKPIsStatusNachBearbeitung.statusNachBearbeitungFraEuAD)
              "
              :ausschlussTyp="ausschlussTypenStatusNachBearbeitung"
              :showPercentage="true"
            />
            <DoughnutChart
              style="min-width: 500px; height: 400px"
              :data="isBusy ? [] : getLeadingKPIs.gesamtverteilungStatusMuenchenBerlinAD"
              :fillArray="fills"
              :title="'Status MUC/BER: Fern'"
            />
            <DoughnutChart
              style="min-width: 500px; height: 400px"
              :data="isBusy ? [] : getLeadingKPIsStatusNachBearbeitung.statusNachBearbeitungMucBerFernAD"
              :fillArray="fillsStatusNachBearbeitung"
              :doNotShowTypes="['Aufpreis <200', 'Total']"
              :title="'Status nach Bearbeitung MUC/BER: Fern'"
              :innerLabelSubtitle="'positiv'"
              :totalOverwrite="
                getTotalOverwrite(getLeadingKPIsStatusNachBearbeitung.statusNachBearbeitungMucBerFernAD)
              "
              :ausschlussTyp="ausschlussTypenStatusNachBearbeitung"
              :showPercentage="true"
            />
            <DoughnutChart
              style="min-width: 500px; height: 400px"
              :data="isBusy ? [] : getLeadingKPIs.gesamtverteilungStatusHamDusBerMucAD"
              :fillArray="fills"
              :title="'Status HAM/DUS/BER/MUC: EU'"
            />
            <DoughnutChart
              style="min-width: 500px; height: 400px"
              :data="isBusy ? [] : getLeadingKPIsStatusNachBearbeitung.statusNachBearbeitungHamDusMucBerEuAD"
              :fillArray="fillsStatusNachBearbeitung"
              :doNotShowTypes="['Aufpreis <200', 'Total']"
              :innerLabelSubtitle="'positiv'"
              :totalOverwrite="
                getTotalOverwrite(getLeadingKPIsStatusNachBearbeitung.statusNachBearbeitungHamDusMucBerEuAD)
              "
              :title="'Status nach Bearbeitung HAM/DUS/BER/MUC: EU'"
              :ausschlussTyp="ausschlussTypenStatusNachBearbeitung"
              :showPercentage="true"
            />
          </div>
        </div>
      </b-overlay>
    </template>
  </FilterOverview>
</template>

<script>
import { AgChartsVue } from 'ag-charts-vue';
import DoughnutChart from '@/components/flugverfuegbarkeit/doughnut-chart.vue';
import KPIFilter from '@/components/flugverfuegbarkeit/kpi-filter.vue';
import BarChart from '@/components/flugverfuegbarkeit/bar-chart.vue';
import FilterOverview from '@/components/common/filter-overview.vue';
import {
  GET_DATA_WITH_ALL_GROUPINGS,
  SET_ZEITRAUM_FILTER,
  SET_REGIONSMANAGER_FILTER,
  RESET_REGIONSMANAGER_FILTER,
} from '@/core/flugverfuegbarkeit/stores/kpi-fvc.module';
import { mapGetters, mapState } from 'vuex';
import DateRangePicker from '@/components/common/date-range-picker.vue';
import SimpleProgressChart from '@/components/flugverfuegbarkeit/simple-progress-chart.vue';

export default {
  components: {
    DoughnutChart,
    BarChart,
    FilterOverview,
    KPIFilter,
    DateRangePicker,
    AgChartsVue,
    SimpleProgressChart,
  },
  data() {
    return {
      fills: ['#ebcc87', '#c16068', '#DAA17B', '#a2bf8a'],
      fillsStatusNachBearbeitung: [
        '#ebcc87',
        '#a2bf8a',
        '#FF7477',
        '#00A878',
        '#c16068',
        '#f4e285',
        '#8cb369',
        '#f94144',
      ],
      kpiSites: ['Dashboard', 'Leading', 'Historie'],
      regionsmanagers: ['Alle', 'STGE', 'SYKE'],
      ausschlussTypenStatusNachBearbeitung: [
        'RQs Verf. n. geschaffen',
        'Ns Verf. n. geschaffen',
        'Aufpreis >200 Verf. n. geschaffen',
        'Total',
      ],
    };
  },
  computed: {
    ...mapGetters(['getLeadingKPIs', 'getLeadingKPIsStatusNachBearbeitung']),
    ...mapState({
      isBusy: state => state.kpiFvc.isBusy,
      regionsmanagersSelected: state => state.kpiFvc.filters.regionsmanager,
      zeitraumSelected: state => state.kpiFvc.filters.zeitraum,
    }),
  },
  created() {
    if (this.getLeadingKPIs === null) this.$store.dispatch(GET_DATA_WITH_ALL_GROUPINGS);
  },
  methods: {
    onRegionFilterClick($event) {
      const { id } = $event.target;
      if (this.activeButton !== id) {
        this.activeButton = id;
      }
    },
    changeRegionsmanagerFilter(kuerzel) {
      this.regionsmanagerFilterSelected = kuerzel;
    },
    setZeitraum(start, end) {
      if (start !== this.zeitraumSelected.start && end !== this.zeitraumSelected.end) {
        this.$store.commit(SET_ZEITRAUM_FILTER, {
          start: start || null,
          end: end || null,
        });
        this.$store.dispatch(GET_DATA_WITH_ALL_GROUPINGS);
      }
    },
    onRegionsmanagerFilterClick($event) {
      const { id: regionsmanager } = $event.target;
      if (regionsmanager === 'Alle') {
        this.$store.commit(RESET_REGIONSMANAGER_FILTER);
      } else if (!this.regionsmanagersSelected.includes(regionsmanager)) {
        this.$store.commit(SET_REGIONSMANAGER_FILTER, [...this.regionsmanagersSelected, regionsmanager]);
      } else {
        this.$store.commit(
          SET_REGIONSMANAGER_FILTER,
          this.regionsmanagersSelected.filter(
            regionsmanagerSelected => regionsmanagerSelected !== regionsmanager
          )
        );
      }

      // Wenn alle angeklickt sind, resette
      if (this.regionsmanagersSelected.length === this.regionsmanagers.length - 1) {
        this.$store.commit(RESET_REGIONSMANAGER_FILTER);
      }
    },
    getTotalOverwrite(dataStatusNachBearbeitung) {
      return dataStatusNachBearbeitung.find(item => item.type === 'Total').count;
    },
  },
};
</script>
